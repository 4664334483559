import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { Tab, Tabs } from '@mui/material';
import { Collapse } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  devlabLastSubmissionType,
  devlabResultOutputType,
  devlabResultType,
} from '../../../../@types/devlab';
import Icon from '../../../../components/Iconify';
import useResponsive from '../../../../hooks/useResponsive';
import axiosInstance from '../../../../utils/axios';

interface OutputCardProps {
  index: number;
  data: devlabResultOutputType;
}

interface TaskResultProps {
  codeValue: devlabLastSubmissionType | null;
  taskResult?: devlabResultType | null;
}

interface ResultComponentProps {
  result?: devlabResultType;
}

interface PlaygroundComponentProps {
  languageId?: number;
  sourceCode?: string | null;
}

function OutputCard({ index, data }: OutputCardProps) {
  const [open, setOpen] = useState<boolean>(false);

  const resultConfig = {
    pass: {
      icon: 'ph:check-circle-light',
      title: 'ผ่านแล้ว',
      color: 'secondary.main',
    },
    fail: {
      icon: 'system-uicons:cross-circle',
      title: 'ไม่ผ่าน',
      color: 'error.main',
    },
    warning: {
      icon: 'fluent:info-28-regular',
      title: 'ผิดพลาด',
      color: 'warning.main',
    },
  };

  const handleResultType = (data: devlabResultOutputType) => {
    if (data.stderr || data.compile_output) return resultConfig['warning'];
    else if (data.status) return resultConfig['pass'];
    else return resultConfig['fail'];
  };

  const handleOpenOutputCard = () => {
    if (
      data?.stdin !== null ||
      data?.stdout !== null ||
      data?.stderr !== null ||
      data?.compile_output !== null
    )
      setOpen(!open);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        borderRadius: '8px',
        paddingX: '20px',
        paddingY: '15px',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => handleOpenOutputCard()}
        sx={{ cursor: 'pointer' }}
      >
        <Stack direction="row" alignItems="center" gap="15px">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: 'grey.700',
              width: '25px',
              height: '25px',
              borderRadius: '100%',
            }}
          >
            <Typography>{index}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap="4px"
            sx={{
              borderRadius: '8px',
              backgroundColor: handleResultType(data).color,
              paddingY: '5px',
              paddingX: '15px',
            }}
          >
            <Icon
              icon={handleResultType(data).icon}
              sx={{ width: '20px', height: '20px' }}
            />
            <Typography variant="subtitle2">
              {handleResultType(data).title}
            </Typography>
          </Stack>
        </Stack>
        <Icon
          icon={
            open
              ? 'material-symbols:keyboard-arrow-up-rounded'
              : 'material-symbols:keyboard-arrow-down-rounded'
          }
          sx={{
            width: '25px',
            height: '25px',
            cursor:
              data?.stdin || data?.stdout || data.stderr || data?.compile_output
                ? 'pointer'
                : 'not-allowed',
            color:
              data?.stdin || data?.stdout || data.stderr || data?.compile_output
                ? 'text.primary'
                : 'text.disabled',
          }}
        />
      </Stack>
      <Collapse in={open}>
        <Grid container sx={{ marginTop: '18px' }} columnSpacing="5px">
          {data?.stdin !== null && (
            <Grid
              item
              xs={data?.stdout || data.stderr || data?.compile_output ? 6 : 12}
            >
              <Typography>Input : </Typography>
              <Box
                sx={{
                  backgroundColor: 'background.paper',
                  padding: '16px',
                  marginTop: '8px',
                  marginBottom: '18px',
                  color: 'grey.500',
                  wordBreak: 'normal',
                  whiteSpace: 'pre',
                  fontFamily: 'monospace, monospace',
                  overflow: 'auto',
                }}
              >
                {data.stdin ?? ' '}
              </Box>
            </Grid>
          )}

          {(data?.stdout || data.stderr || data?.compile_output) && (
            <Grid item xs={data?.stdin !== null ? 6 : 12}>
              <Typography>Output : </Typography>
              <Box
                sx={{
                  fontFamily: 'monospace, monospace',
                  backgroundColor: 'background.paper',
                  padding: '16px',
                  marginTop: '8px',
                  marginBottom: '18px',
                  color: 'grey.500',
                  wordBreak: 'normal',
                  whiteSpace: 'pre',
                  overflow: 'auto',
                }}
              >
                {(data?.stdout || data.stderr || data?.compile_output) ?? ' '}
              </Box>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Box>
  );
}

function ResultComponent({ result }: ResultComponentProps) {
  return (
    <Box>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        Results
      </Typography>
      <Stack direction="column" gap="7px">
        {result?.results?.map((item: devlabResultOutputType, index: number) => (
          <OutputCard
            key={`task-result-${index}`}
            index={index + 1}
            data={item}
          />
        ))}
      </Stack>
    </Box>
  );
}

function PlaygroundComponent({
  languageId,
  sourceCode,
}: PlaygroundComponentProps) {
  const mediaScreen = useResponsive('down', 600);

  const [testCase, setTestCase] = useState<string>('');
  const [testResult, setTestResult] = useState<devlabResultOutputType | null>(
    null,
  );
  const [loading, setLoading] = useState(false);

  const onSubmitPlayground = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/devlab/playground', {
        langId: languageId,
        code: sourceCode,
        input: testCase,
      });
      setTestResult(response.data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'start', sm: 'center' }}
        justifyContent="space-between"
      >
        <Typography>สร้าง Test-Case ด้วยตัวเอง</Typography>
        <Button
          disabled={loading}
          size="small"
          color="secondary"
          variant="contained"
          sx={{ paddingX: '20px', width: mediaScreen ? '100%' : 'auto' }}
          onClick={() => onSubmitPlayground()}
        >
          <Typography variant="body2">Run</Typography>
          <Icon
            icon="ic:round-navigate-next"
            sx={{ width: '20px', height: '20px' }}
          />
        </Button>
      </Stack>

      <Grid
        container
        columns={{ xs: 1, sm: 2 }}
        columnSpacing="5px"
        sx={{ marginTop: '20px' }}
      >
        <Grid item xs={1} sx={{ overflow: 'hidden', height: '100%' }}>
          <Typography variant="body2" sx={{ marginBottom: '5px' }}>
            Input:
          </Typography>
          <TextField
            sx={{
              width: '100%',
              backgroundColor: 'background.default',
              borderRadius: '5px',
              textarea: {
                minHeight: '227px',
                backgroundColor: 'background.default',
              },
            }}
            placeholder="write your testcases here..."
            value={testCase}
            onChange={(e) => setTestCase(e.target.value)}
            multiline
          />
        </Grid>
        <Grid item xs={1} sx={{ overflow: 'hidden' }}>
          <Typography variant="body2" sx={{ marginBottom: '5px' }}>
            Output:
          </Typography>
          <Box
            sx={{
              backgroundColor: 'background.default',
              color: 'text.secondary',
              padding: '10px',
              minHeight: '200px',
              height: '100%',
              whiteSpace: 'pre',
              fontFamily: 'monospace, monospace',
              overflow: 'auto',
              wordBreak: 'normal',
            }}
          >
            {(testResult?.stdout ||
              testResult?.stderr ||
              testResult?.compile_output) ??
              ''}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function TaskResult({ codeValue, taskResult }: TaskResultProps) {
  const [options, setOptions] = useState<number>(1);

  const isEmtryObject = (data: Object): boolean => {
    let result: boolean = false;
    if (Object.keys(data).length === 0) result = true;
    return result;
  };

  const getCountSuccessCase = (): number => {
    let result: number = 0;
    if (taskResult) {
      result = taskResult?.results?.filter(
        (item: devlabResultOutputType) => item.status,
      ).length;
    }

    return result;
  };

  useEffect(() => {
    if (taskResult === null) setOptions(1);
    else if (taskResult?.results && !taskResult.fail) setOptions(2);
  }, [taskResult]);

  return (
    <Box
      id="result"
      sx={{
        borderRadius: '8px',
        backgroundColor: 'background.paper',
        marginBottom: '20px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'background.dark',
          borderStartStartRadius: '8px',
          borderStartEndRadius: '8px',
          paddingX: '20px',
          paddingY: '30px',
        }}
      />

      <Box
        sx={{
          backgroundColor: 'background.paper',
          padding: '20px',
          paddingBottom: '40px',
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={options}
            onChange={(_, newValue) => newValue && setOptions(newValue)}
          >
            <Tab label={<Typography>Playground</Typography>} value={1} />

            {taskResult &&
              !isEmtryObject(taskResult) &&
              taskResult !== null &&
              !taskResult.fail && (
                <Tab
                  value={2}
                  label={
                    <Stack direction="row" alignItems="center" gap="5px">
                      <Typography>Result</Typography>
                      <Box
                        sx={{
                          paddingY: '5px',
                          paddingX: '14px',
                          backgroundColor: 'secondary.main',
                          borderRadius: '6px',
                          color: 'common.white',
                        }}
                      >
                        {`ผ่านแล้ว ${getCountSuccessCase()}/${taskResult?.results?.length}`}
                      </Box>
                    </Stack>
                  }
                />
              )}
          </Tabs>
        </Box>

        <Box sx={{ marginTop: '20px' }}>
          {options === 1 ? (
            <PlaygroundComponent
              languageId={codeValue?.codeLanguageID}
              sourceCode={codeValue?.sourceCode}
            />
          ) : taskResult &&
            !isEmtryObject(taskResult) &&
            taskResult !== null &&
            !taskResult?.fail ? (
            <ResultComponent result={taskResult} />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
