import {
  devlabLastSubmissionType,
  devlabTaskType,
} from '../../../../@types/devlab';

export const devlabTaskInitial: devlabTaskType = {
  id: '',
  devlabId: '',
  devlabTaskId: 1,
  displayOrder: 1,
  maxScore: 0,
  nextTask: '',
  prevTask: '',
  percentProgress: 0,
  userStatus: 'NEVER',
  passedTime: '',
  devlabDetail: {
    id: 1,
    title: '',
    difficulty: 1,
    category: '',
    status: '',
    description: '',
    inputDescription: '',
    outputDescription: '',
    constraint: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    User: {
      id: 1,
      displayName: '',
      imageUrl: '',
      role: '',
    },
    examples: [
      {
        id: 1,
        input: '',
        output: '',
      },
    ],
    testcases: [
      {
        id: 1,
        input: '',
        output: '',
        score: 0,
      },
    ],
  },
  devlab: {
    id: '',
    lessonId: '',
    createdAt: '',
    updatedAt: '',
    allowLanguages: [],
    description: '',
    maxScore: 0,
    percentPassing: 0,
  },
};

export const submissionInitial: devlabLastSubmissionType = {
  id: 1,
  sourceCode: '',
  score: 0,
  totalRuntimeMs: '',
  passed: false,
  createdAt: new Date(),
  codeLanguageID: 1,
};
