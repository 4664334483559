import { Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';

import { DevLabTaskUser } from '@academy/devlab4-type';
import Icon from '../../../../../components/Iconify';
import Image from '../../../../../components/Image';
import useResponsive from '../../../../../hooks/useResponsive';

type StatusType = 'PASS' | 'NOTPASS' | 'NEVER';

interface DevlabTaskProps {
  title?: string;
  level?: number;
  taskId?: string;
  author?: DevLabTaskUser;
  status?: StatusType;
  passDate?: string;
}

function statusTask(status?: StatusType, passDate?: string) {
  if (status === 'NEVER') {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography variant="body1">ไม่เคยทำ</Typography>
      </Stack>
    );
  }

  const contentStatus: any = {
    PASS: {
      text: 'ผ่านแล้ว',
      color: 'success.main',
    },
    NOTPASS: {
      text: 'ยังไม่ผ่าน',
      color: 'error.main',
    },
  };

  return (
    <Stack direction="column" alignItems="center">
      <Typography
        variant="subtitle1"
        sx={{ color: contentStatus?.[status as StatusType].color }}
      >
        {contentStatus?.[status as StatusType].text}
      </Typography>
      {passDate !== '' && passDate && (
        <Typography variant="body2">
          {`ผ่านเมื่อ ${dayjs(passDate).format('DD/MM/YYYY')}`}
        </Typography>
      )}
    </Stack>
  );
}

export default function TaskCard({
  title,
  level = 0,
  taskId,
  author,
  status,
  passDate,
}: DevlabTaskProps) {
  const theme = useTheme();
  const location = useLocation();
  const mediaSize = useResponsive('up', 900);

  return (
    <Grid
      component={Link}
      to={`${location.pathname}/devlab/${taskId}`}
      container
      columns={{ xs: 2, sm: 3 }}
      sx={{
        backgroundColor: 'background.default',
        padding: '30px',
        borderRadius: '8px',
        border: `1px solid`,
        borderColor: theme.palette.grey[700],
        color: 'text.primary',
        textDecoration: 'none',
      }}
    >
      <Grid item xs={2} sm={1}>
        <Typography variant="h5">{title}</Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'start', sm: 'center' }}
          gap={{ xs: '5px', sm: '10px' }}
        >
          {mediaSize && (
            <Typography variant="caption">ระดับความยาก :</Typography>
          )}

          <Stack direction="row" alignItems="center" sx={{ marginTop: '-4px' }}>
            {Array(5)
              .fill(0)
              .map((_, index: number) => (
                <Icon
                  key={`star-level-${index}`}
                  icon="material-symbols:star-rounded"
                  sx={{
                    fontSize: '18px',
                    marginLeft: '-4px',
                    color: index < level ? 'primary.main' : 'grey.600',
                  }}
                />
              ))}
          </Stack>
        </Stack>
      </Grid>

      {mediaSize && (
        <Grid item xs={1}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            gap={{ xs: '3px', sm: '8px' }}
          >
            <Typography variant="body2">สร้างสรรค์โดย</Typography>
            <Stack direction="row" alignItems="center" gap="3px">
              <Image
                src={author?.imageUrl}
                sx={{
                  width: '30px',
                  height: '30px',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: '100%',
                }}
              />
              <Typography variant="body2">{author?.displayName}</Typography>
            </Stack>
          </Stack>
        </Grid>
      )}

      <Grid item xs={2} sm={mediaSize ? 1 : 2}>
        <Stack direction="row" justifyContent="end" sx={{ height: '100%' }}>
          {statusTask(status, passDate)}
        </Stack>
      </Grid>
    </Grid>
  );
}
