import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { MenuItem, Modal, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import {
  davlabLanguageType,
  devlabLanguageType,
  devlabLastSubmissionType,
  devlabResultType,
} from '../../../../@types/devlab';
import Icon from '../../../../components/Iconify';
import CodeEditor from '../../../../components/codemirror';
import SubmissionModal from '../../../../components/modal/SubmissionModal';
import axiosInstance from '../../../../utils/axios';

import { DevLabTaskSubmission } from '@academy/devlab4-type';
import { submissionInitial } from './initialDevlab';

interface TaskGraderProps {
  taskId: number;
  nextTask: string | null;
  codeValue: devlabLastSubmissionType | null;
  setCodeValue: Function;
  onSubmit: Function;
  result?: devlabResultType | null;
  languageList: devlabLanguageType[];
  handleScroll: Function;
}

interface ChangeLanguageModalProps {
  title: string;
  icon?: string;
  open: boolean;
  onClose: Function;
  onAction: Function;
}

type LanguageOptionType = {
  key: string;
  title: string;
  example?: string;
  id?: number;
};

const languageOption: LanguageOptionType[] = [
  { key: 'python', title: 'Python' },
  { key: 'java', title: 'Java' },
  { key: 'javascript', title: 'JavaScript' },
  { key: 'cpp', title: 'C++' },
  { key: 'csharp', title: 'C#' },
  { key: 'c', title: 'C' },
  { key: 'kotlin', title: 'Kotlin' },
  { key: 'go', title: 'Go' },
  { key: 'ruby', title: 'Ruby' },
  { key: 'swift', title: 'Swift' },
];

function ChangeLanguageModal({
  title,
  icon = 'ep:warning',
  open,
  onClose,
  onAction,
}: ChangeLanguageModalProps) {
  return (
    <Modal open={open} onClose={() => onClose()}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'background.paper',
          width: '100%',
          height: 'auto',
          maxWidth: '300px',
          borderRadius: '8px',
          paddingY: '45px',
          paddingX: '20px',
        }}
      >
        <Stack direction="column" alignItems="center">
          <Icon
            icon={icon}
            sx={{
              width: '85px',
              height: '85px',
              color: 'primary.main',
            }}
          />
          <Typography
            variant="h6"
            sx={{
              marginTop: '20px',
              marginBottom: '40px',
              textAlign: 'center',
            }}
          >
            {title}
          </Typography>
          <Stack direction="row" justifyContent="center" gap="8px">
            <Button
              variant="outlined"
              color="tertiary"
              onClick={() => onClose()}
            >
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              sx={{ color: 'text.button' }}
              onClick={() => onAction()}
            >
              ตกลง
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

export default function TaskGrader({
  taskId,
  nextTask,
  codeValue,
  setCodeValue,
  onSubmit,
  result,
  languageList,
  handleScroll,
}: TaskGraderProps) {
  const [option, setOption] = useState<string>('');
  const [optionList, setOptionList] = useState<LanguageOptionType[] | []>([]);

  const [openChangeLangModal, setOpenChangeLangModal] = useState<any>({
    open: false,
    action: 'example',
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [chnageLangLoading, setChangeLangLoading] = useState<boolean>(true);
  const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false);

  const themeMode = useTheme();

  const handleSubmitData = () => {
    setOpenSubmitModal(true);
    onSubmit(codeValue?.codeLanguageID, codeValue?.sourceCode);
  };

  const handleChangeLanguage = async (languageOption: string) => {
    const langId = await optionList.find(
      (item: LanguageOptionType) => item.key === languageOption,
    )?.id;
    setOption(languageOption);
    setCodeValue({ ...codeValue, langId: langId });
    getLastSubmissionById(langId as number);
  };

  const handleChangeCode = (value: string) => {
    if (codeValue !== null) {
      setCodeValue({ ...codeValue, sourceCode: value });
    } else {
      setCodeValue({
        ...submissionInitial,
        code: null,
        langId: optionList.find(
          (item: LanguageOptionType) => item.key === option,
        )?.id,
      });
    }
  };

  const handleGetData = async () => {
    if (languageList.length) {
      const options = getLanguageCode();
      await getLastSubmission(options);
    }
  };

  const handleExampleCode = (type: 'ClOSE' | 'ACTION') => {
    if (type === 'ACTION') {
      if (openChangeLangModal.action === 'lastSubmit') {
        setCodeValue(openChangeLangModal.playLoad);
        setChangeLangLoading(false);
        setOpenChangeLangModal({ ...openChangeLangModal, open: false });
      } else {
        const langId = optionList.find(
          (item: LanguageOptionType) => item.key === option,
        )?.id;
        getExampleCode(langId);
        setOpenChangeLangModal({ ...openChangeLangModal, open: false });
      }
    } else {
      setOpenChangeLangModal({ ...openChangeLangModal, open: false });
      setChangeLangLoading(false);
    }
  };

  const handleSubmissionClosed = () => {
    setOpenSubmitModal(false);
    handleScroll('result');
  };

  const getLanguageCode = (): LanguageOptionType[] => {
    try {
      if (languageList.length) {
        const data: LanguageOptionType[] = languageList.map(
          (item: davlabLanguageType) => ({
            key: languageOption.filter(
              (language: LanguageOptionType) =>
                language.title === item.text.split(' ')[0],
            )[0]?.key,
            title: item.text,
            id: item.value,
            example: item?.example ?? '// Source Code Naaa',
          }),
        );
        setOptionList(data);
        return data;
      }
      return [];
    } catch {
      return [];
    }
  };

  const getLastSubmission = async (options: LanguageOptionType[]) => {
    try {
      const langIds = options
        .map((item: LanguageOptionType) => item.id)
        .join(',');

      if (langIds) {
        const response = await axiosInstance.get<DevLabTaskSubmission>(
          `/devlab/tasks/${taskId}/submission`,
          {
            params: { langIds },
          },
        );
        const submissionData = response.data;

        const optionSelected = options?.find(
          (item: LanguageOptionType) =>
            item.id === submissionData.codeLanguageID,
        );

        setOption(optionSelected?.key as string);
        setCodeValue(submissionData);

        setLoading(false);
        setChangeLangLoading(false);
      }
    } catch {
      if (options.length) {
        setCodeValue({
          ...codeValue,
          code: options[0].example,
          langId: options[0]?.id,
        });
        setOption(options[0]?.key);
        setLoading(false);
        setChangeLangLoading(false);
      }
    }
  };

  const getExampleCode = async (langId?: number) => {
    const languageData = await optionList.find((item: LanguageOptionType) => {
      if (langId) return item.id === langId;
      else return item.key === option;
    });

    const exampleCode = languageData?.example;
    setCodeValue((prev: devlabLanguageType) => ({
      ...prev,
      code: exampleCode,
    }));
  };

  const getLastSubmissionById = async (languageId: number) => {
    try {
      setChangeLangLoading(true);
      const response = await axiosInstance.get<DevLabTaskSubmission>(
        `/devlab/tasks/${taskId}/submission`,
        {
          params: { langIds: languageId },
        },
      );
      const submissionData = response.data;
      setOpenChangeLangModal({
        open: true,
        action: 'lastSubmit',
        playLoad: submissionData,
      });
    } catch {
      getExampleCode(languageId);
      setChangeLangLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, [languageList]);

  return (
    <Box
      id="grader"
      sx={{
        borderRadius: '8px',
        backgroundColor: 'background.paper',
        marginBottom: '20px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
      }}
    >
      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        gap="10px"
        sx={{
          backgroundColor: 'background.dark',
          borderStartStartRadius: '8px',
          borderStartEndRadius: '8px',
          paddingX: '20px',
          paddingY: '30px',
        }}
      >
        {chnageLangLoading && <CircularProgress size="30px" />}

        <Button
          size="small"
          variant="contained"
          color="tertiary"
          sx={{ height: '35px' }}
          disabled={chnageLangLoading || languageList.length === 0}
          onClick={() =>
            setOpenChangeLangModal({ open: true, action: 'example' })
          }
        >
          <Typography variant="subtitle2">Example</Typography>
        </Button>

        <Select
          size="small"
          value={option}
          disabled={chnageLangLoading}
          onChange={(value) => handleChangeLanguage(value.target.value)}
          sx={{
            backgroundColor: 'grey.0',
            color: 'common.black',
            height: '35px',
            paddingX: '10px',
            fontSize: 'body2',
          }}
        >
          {optionList.map((item: LanguageOptionType, index: number) => (
            <MenuItem value={item.key} key={`language-option-${index}`}>
              <Stack direction="row" gap="5px">
                <Typography variant="body2">
                  {item.title.split(' ')[0]}
                </Typography>
                <Typography variant="body2" sx={{ color: '#8f8f8f' }}>
                  {item.title.split(' ').slice(1, 10).join(' ')}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>

        <Button
          size="small"
          variant="contained"
          color="secondary"
          disabled={chnageLangLoading}
          onClick={() => handleSubmitData()}
          sx={{ height: '35px', paddingX: '20px' }}
        >
          <Stack direction="row" alignItems="center" gap="5px">
            <Typography variant="subtitle2">Submit</Typography>
            <Icon
              icon="material-symbols:upload-rounded"
              sx={{ width: '19px', height: '19px' }}
            />
          </Stack>
        </Button>
      </Stack>

      <Box
        sx={{
          position: 'relative',
          backgroundColor:
            themeMode.palette.mode === 'light' ? 'background.paper' : '#272C35',
          paddingY: '10px',
          paddingX: '8px',
        }}
      >
        {chnageLangLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 10,
              opacity: '80%',
              backgroundColor: 'background.default',
              width: '100%',
              height: '100%',
            }}
          />
        )}
        {!loading && (
          <CodeEditor
            theme={themeMode.palette.mode}
            language={option ?? 'python'}
            code={codeValue?.sourceCode}
            onChangeCode={(value: string) => handleChangeCode(value)}
          />
        )}
      </Box>

      <SubmissionModal
        data={result}
        nextTask={nextTask}
        open={openSubmitModal}
        onClose={handleSubmissionClosed}
        onSubmit={handleSubmitData}
      />

      <ChangeLanguageModal
        title={
          openChangeLangModal.action === 'example'
            ? 'ต้องการเรียกโค้ดตัวอย่างของภาษาดังกล่าวมาแทนที่โค้ดของคุณหรือไม่?'
            : 'ต้องการแทนที่โค้ดล่าสุดในภาษา\nดังกล่าวหรือไม่'
        }
        icon={
          openChangeLangModal.action === 'lastSubmit'
            ? 'mingcute:warning-fill'
            : undefined
        }
        open={openChangeLangModal.open}
        onClose={() => handleExampleCode('ClOSE')}
        onAction={() => handleExampleCode('ACTION')}
      />
    </Box>
  );
}
